<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Program Change Status: {{ termLabel }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <h3>Current Status: {{ statusText }}</h3>
        <p v-if="status === 'Submitted'">You have submitted your change request. The Records office will do an initial review of this request, and will adjust the advisors as needed. Once that is finished, the request will be sent to your advisors for approval.</p>
        <p v-else-if="status === 'Pending'">The request is now pending review by your advisors. You should see an approval in the timeline for each advisor that has approved the request. Please contact your advisor(s) if you have any questions or to work out the approval with them.</p>
        <p v-else-if="status === 'Approved'">The request has been approved by all parties and will be processed within Banner. This typically is done within a few business days, depending on the workload in the Records Office.</p>
        <p v-else-if="status === 'Processed'">Your requested changes have been completed. If you would like to make any further changes, please click the button below to restart your change request.</p>
      </v-card-text>
    </v-card>
    <submission-card :data="submission" class="mt-2 mb-2" @update="(data) => $emit('update', data)"></submission-card>
    <v-card v-if="showAddMessage">
      <v-card-text>
        <h3>Add a Message</h3>
        <v-textarea v-model="comment" label="Message" style="margin-top:1em" rows="3" outlined hide-details></v-textarea>
        <v-checkbox v-model="emailAdvisors" label="Email this message to my advisor(s)"></v-checkbox>
        <v-checkbox v-model="emailRecords" label="Email this message to the Records Office"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="comment === ''" @click="addComment" color="info">Add Message</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="showAddMessage = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <v-btn v-else @click="showAddMessage = true">Add Message</v-btn>
    <h3 style="margin-top:1em">Change Request Timeline</h3>
    <v-timeline dense>
      <v-timeline-item v-for="({ name, date, text, icon, color }, index) in timeline" :key="'timeline-' + index" :color="color">
        <template v-slot:icon>
          <v-icon small color="white">{{ icon }}</v-icon>
        </template>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>{{ name }}</v-col>
              <v-col class="text-right">{{ stringFormatDate(date) }}</v-col>
            </v-row>
            <v-divider style="margin: .2em 0"></v-divider>
            <p style="margin-bottom:0" v-html="text"></p>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    SubmissionCard: () => import('./submission')
  },
  props: {
    submission: {
      type: Object,
      required: true
    }
  },
  setup (props, { root }) {
    const dataLoaded = ref(false)
    const term = computed(() => props.submission.term || '')
    const termLabel = ref('')
    const status = computed(() => props.submission.status || 'Unsubmitted')
    const statusText = computed(() => {
      switch (props.submission.status) {
        case 'Submitted':
          return 'Submitted; Pending Initial Review by the Records Office'
        case 'Pending':
          return 'Pending Advisor Review'
        case 'Approved':
          return 'Approved; Pending Banner Update'
        case 'Processed':
          return 'Complete; Change Request is Updated in Banner'
      }
      return props.submission.status || 'Unsubmitted'
    })
    const timeline = computed(() => 'timeline' in props.submission && Array.isArray(props.submission.timeline) ? props.submission.timeline.filter(({ visibleToStudent }) => !!visibleToStudent).reverse() : [])

    watch(() => term, () => {
      if (term.value != null && term.value !== '') {
        root.$feathers.service('system/term').find({ query: { term: term.value } }).then(({ data }) => {
          if (data.length > 0) {
            termLabel.value = data[0].label
          }
        })
      }
    })

    const showAddMessage = ref(false)
    const comment = ref('')
    const emailAdvisors = ref(false)
    const emailRecords = ref(false)
    function addComment () {}

    return {
      dataLoaded,
      term,
      termLabel,
      status,
      statusText,
      timeline,
      showAddMessage,
      comment,
      emailAdvisors,
      emailRecords,
      addComment,
      stringFormatDate
    }
  }
}
</script>
